const config = require('./config/index');

module.exports = {
  locales: config.i18n.locales,
  defaultLocale: config.i18n.defaultLocale,
  pages: {
    '*': [
      'common',
      'routes',
      'footer',
      'header',
      'value-proposition',
      'vehicle-picker',
      'help',
      'product',
      'vehicle',
      'my-cars',
      'cookie-concent',
      'error404',
      'us-modal',
      'grammar',
      'parts-and-categories-plural',
      'products',
    ],
    '/': ['home', 'brands', 'categories'],
    '/contacts': ['contacts', 'countries-contacts', 'forms'],
    '/legal-mentions': ['legal', 'countries-contacts'],
    '/terms-and-conditions': ['terms'],
    '/about-us': ['about'],
    '/faqs': ['faqs'],
    '/faqs/[group]': ['faqs'],
    '/nps': ['nps'],
    '/unsubscribe-emails': ['unsubscribe-emails'],
    '/unsubscribe-emails/[token]': ['unsubscribe-emails'],
    '/auto-parts/brands': ['brands'],
    '/auto-parts/[category]/[subCategory]/brands': [
      'brands',
      'vehicle',
      'products',
      'forms',
      'private-area',
    ],
    '/auto-parts/models/[brand]': ['models', 'products'],
    '/auto-parts/[category]/[subCategory]/models/[brand]': [
      'models',
      'vehicle',
      'products',
      'forms',
      'private-area',
    ],
    '/auto-parts/versions/[model]': [
      'versions',
      'products',
      'forms',
      'private-area',
    ],
    '/auto-parts/[category]/[subCategory]/versions/[model]': [
      'versions',
      'vehicle',
      'products',
      'forms',
      'private-area',
    ],
    '/auto-parts/categories': [
      'categories',
      'products',
      'forms',
      'private-area',
    ],
    '/auto-parts/[category]': ['categories', 'products'],
    '/auto-parts/[category]/categories': [
      'categories',
      'products',
      'forms',
      'private-area',
    ],
    '/auto-parts/category/[slug]': ['categories', 'products'],
    '/auto-parts/[category]/category/[slug]': ['categories', 'products'],
    '/auto-parts/vehicle': ['unique-vehicle', 'vehicle', 'products'],
    '/auto-parts/[category]/[subCategory]/[vehicle]': [
      'products',
      'forms',
      'home',
    ],
    '/auto-parts/search': ['products', 'forms', 'home'],
    '/auto-parts/[category]/[subCategory]': [
      'home',
      'product',
      'products',
      'unique-vehicle',
      'vehicle',
      'forms',
      'terms',
    ],
    '/auto-parts/vehicle/[vehicle]': ['unique-vehicle', 'products'],
    'rgx:^/private-area': [
      'private-area',
      'pa-account-details',
      'forms',
      'checkout',
    ],
    'rgx:^/checkout': ['checkout', 'forms', 'login'],
    '/signup': ['signup', 'forms'],
    '/login': ['login', 'forms'],
    '/password-reset': ['password-reset', 'forms'],
    '/password-reset-confirm/[token]': ['password-reset', 'forms'],
    '/create-part-alert': ['private-area', 'forms'],
    '/500': ['error500'],
    '/404': ['error404', 'forms', 'products', 'advanced-search'],
    'rgx:^/advanced-search': [
      'search',
      'home',
      'products',
      'private-area',
      'pa-account-details',
      'forms',
      'checkout',
      'advanced-search',
      'unique-vehicle',
    ],
    '/pro': ['pro'],
    '/auto-parts/[category]': [
      'categories',
      'sub-categories',
      'forms',
      'private-area',
    ],
    '/auto-parts/[category]/category/[slug]': [
      'categories',
      'sub-categories',
      'forms',
      'private-area',
    ],
  },
};
